<template>
  <v-app>
    <div class="content-container mb-10"></div>
    <v-footer app class="text-center">
      <v-row class="d-flex justify-center">
        <v-col cols="auto">
          by 정하
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  
};
</script>